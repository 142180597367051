import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: 'Who is in this dataset?',
    answer: 'Federal and state candidates running for office as well as political organizations active in the 2020 election cycle in the United States. The exact breakdown is in our working paper.',
  },
  {
    question: 'When did you start collecting these emails?',
    answer: 'We started collecting emails at various times starting December 2, 2019.',
  },
  {
    question: 'How did you collect these emails?',
    answer: 'We created an automated email signup bot to collect these emails. More details about the method are in our working paper.',
  },
  {
    question: 'Why are the URLs redacted?',
    answer: 'The URLs in the emails are unique to each user and are tracked. Clicking these may signal the sender about interest in the emails. Releasing the URLs also exposes unsubscribe links which is undesirable. Therefore, we removed all the URLs from the emails displayed on this website.',
  },
  {
    question: 'How often is the website updated?',
    answer: 'Every 5-8 hours or so, depending on the volume of emails on any given day.',
  },
  {
    question: 'Why am I seeing emails from a different sender within all of the emails of a particular sender?',
    answer: "For each candidate we signed up with a unique email address. Each sender's emails contains all the emails sent to that email address. This includes emails that may be received if the sender shared the email address with other entities. More details about privacy and email sharing are in our working paper.",
  },
  {
    question: 'Why do I see errors/no content in the HTML/Plain tabs of some emails?',
    answer: 'Sometimes emails may not contain an HTML part. Other times, our email client may have failed to record the HTML part accurately. In both cases, you may see an error message displayed in the "HTML" tab of an email. Please reach out to us if you would like to access a particular email that is not available on the website. If an email does not contain a plain part, you may see a blank page on the "Plain" tab.',
  },
  {
    question: "Why does the 'preview' mode of each email show text that does not appear when I click and view the email?",
    answer: 'That text is the email preheader which is hidden by default but appears when the email style is not applied (e.g., in preview mode). Read more about email preheaders here.',
  },
  {
    question: "I've been collecting emails from sender X too. Why does my set of emails not always overlap with the emails in this corpus?",
    answer: 'Campaigns may target their emails based on the information you provide when signing up (e.g. the zip code). Even within emails with comparable information, there may be variation due to A/B testing. Donating to the campaigns or being engaged (e.g., clicking on links) may result in a different set of emails.',
  },
  {
    question: "Are there other datasets like this?",
    answer: 'The Archive of Political Emails maintains a partially overlapping collection of emails from a variety of political actors and organizations. Their collection starts five months earlier than ours (July 2019) and includes some types of senders that we chose not to collect including Non-Governmental Organizations, 501 organizations and international organizations. For those sender types that are in scope for both of our datasets, our collection of senders is more complete: we miss only 1 sender out of a sample of 30 that they have; conversely they miss 89.4% of the senders in our corpus. DCinbox collects official newsletters from each member of congress while our dataset focuses on campaign communications from candidates, political parties and other organizations.',
  },
  {
    question: "Can I use this dataset for research?",
    answer: 'Please see the data section for more details.',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const FAQ = () => {
  return (
    <section className="py-0 bg-dark" id="faq">
      <div className="container bg-primary">
        <div className="max-w-3xl">


         <h2 className="border-b-2 border-red-400">Frequently Asked Questions</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
        {faqs.map((faq) => (
          <Disclosure as="div" key={faq.question} className="pt-6">
            {({ open }) => (
              <>
                <dt className="text-lg">
                  <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
                    <span className="font-medium text-gray-900">{faq.question}</span>
                    <span className="flex items-center ml-6 h-7">
                      <ChevronDownIcon
                        className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                        aria-hidden="true"
                      />
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="pr-12 mt-2">
                  <p className="text-base text-gray-500">{faq.answer}</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
          </dl>
        </div>
      </div>
    </section>
  )
}

export default FAQ
